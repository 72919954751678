<template>
  <div>
    <v-row
      no-gutters
    >
      <v-col class="d-flex justify-center">
        <v-card style="color: #000" flat  width="1385px">
          <h3
            class="text-md-h4 text-sm-h5 font-weight-bold pl-2 pb-6"
            style="color: #2b4570"
          >
            Our course highlights
          </h3>
          <v-select
            class="mt-8"
            v-if="screenWidth < 600"
            v-model="selectedSection"
            :items="
              sections.map((section, i) => {
                return {
                  text: `${section.title} (${section.contents.length})`,
                  value: i
                };
              })
            "
            dense
            outlined
          >
          </v-select>
          <v-slide-group
            v-else
            v-model="selectedSection"
            mandatory
            show-arrows
            class="pl-2 mt-8"
          >
            <v-slide-item
              v-for="(section, index) in sections"
              :key="index"
              v-slot="{ active, toggle }"
            >
              <v-btn
                depressed
                class="mr-3 pa-2 d-flex align-center xl:text-sm lg:text-sm md:text-sm sm:text-xs"
                :class="classes.slideItem.outer[active]"
                :input-value="active"
                @click="toggle"
              >
                {{ section.title }}
                <div
                  class="ml-2 rounded"
                  :class="classes.slideItem.inner[active]"
                >
                  {{ section.contents.length }}
                </div>
              </v-btn>
            </v-slide-item>
          </v-slide-group>
          <v-expansion-panels v-model="expandedModule" flat class="mt-sm-8 mt-md13">
            <v-expansion-panel
              v-for="(module_id, index) in selected"
              :key="index"
              class="my-1"
              active-class="panel-active"
            >
              <v-expansion-panel-header
                class="pa-0 ma-0 my-1"
                hide-actions
                disable-icon-rotate
              >
                <v-card flat color="#F4F4F4" class="py-5 px-4">
                  <div
                    class="d-flex align-center"
                    :class="classes.header"
                  >
                    <div class="d-flex flex-grow-1 clickable">
                      <b
                        >{{ index + 1 }}. &nbsp;
                        {{ moduleName(module_id) }}</b
                      >
                    </div>
                    <v-btn icon>
                      <v-icon class="panel-arrow" size="25"
                        >{{
                          index != expandedModule ? "mdi-plus" : "mdi-close"
                        }}
                      </v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <public-module-contents
                  style="color: #f4f4f4"
                  :contents="getModuleContents(module_id)"
                  :module_id="module_id"
                ></public-module-contents>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
     </v-row>
  </div>
</template>

<script>
import PublicModuleContents from "./ModuleContents.vue";
import { ResponsivenessUtils } from "/global/utils/mixins";

export default {
  name: "CourseDetails",
  components: { PublicModuleContents },
  mixins: [ResponsivenessUtils],
  data() {
    return {
      selectedSection: 0,
      expandedModule: undefined,
    };
  },
  watch: {
    selectedSection(newVal) {
      if(newVal) {
        this.expandedModule = undefined
      }
    }
  },
  computed: {
    advertisedContents() {
      return [
        {
          text: "Interactive Lectures",
          count: "30+",
          icon: require("@/assets/ielts/course-details/material-symbols.svg"),
          color: "#c0ecff"
        },
        {
          text: "Question Specific Practice Tests",
          count: "50+",
          icon: require("@/assets/ielts/course-details/Group.svg"),
          color: "#e8f1ff"
        },
        {
          text: "Full Length Mock Tests",
          count: "20+",
          icon: require("@/assets/ielts/course-details/healthicons.svg"),
          color: "#edf4ff"
        },
        {
          text: "Dynamic Lecture Slides",
          count: "",
          icon: require("@/assets/ielts/course-details/Vector.svg"),
          color: "#f2f7ff"
        },
        {
          text: "Module Based Vocabulary Boost",
          count: "",
          icon: require("@/assets/ielts/course-details/Boost.svg"),
          color: "#fafcff"
        }
      ];
    },
    modules() {
      return this.$store.state.publicCourseDetails.modules;
    },
    sections() {
      return this.$store.state.publicCourseDetails.sections;
    },
    allContents() {
      return this.$store.state.publicCourseDetails.object_details;
    },
    allExams() {
      return this.$store.state.publicCourseDetails.exams;
    },
    selected() {
      if(this.sections) {
        return this.sections[this.selectedSection].contents;
      }
      return []
    },
    classes() {
      return {
        slideItem: {
          outer: {
            true: "primary white--text",
            false: "bg-white  black--text"
          },
          inner: {
            true: "white primary--text",
            false: "primary white--text"
          }
        },
        header: `px-${this.screenWidth < 600 ? 2 : 4}`
      };
    }
  },
  created() {
    this.getPublicCourseDetails();
  },
  methods: {
    async getPublicCourseDetails() {
      await this.$store.dispatch("fetchPublicCourseDetails");
    },
    expandedModuleIndex(index) {
      this.expandedModule = index;
    },
    moduleName(moduleId) {
      return this.modules[moduleId].title;
    },
    getModuleContents(moduleId) {
      let contents = this.modules[moduleId].contents;
      let moduleContents = [];
      contents.forEach(content => {
        if (content.id in this.allContents) {
          moduleContents.push(this.allContents[content.id]);
        }
        if (content.id in this.allExams) {
          moduleContents.push(this.allExams[content.id]);
        }
      });
      return moduleContents;
    },
    processAboutVid() {
      let url =
        "https://youtu.be/ccsz9FRy-nk?list=PLC3y8-rFHvwgeQIfSDtEGVvvSEPDkL_1f";
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      let code = match && match[2].length === 11 ? match[2] : null;
      if (!code) return this.course.about_video;
      else {
        return `https://www.youtube.com/embed/${code}`;
      }
    }
  }
};
</script>

<style>
.heading1 {
  color: #2b4570;
  font-family: Roboto;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}
</style>
