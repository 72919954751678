var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-list",
            _vm._l(_vm.contents, function (content, index) {
              return _c(
                "v-list-item",
                { key: index },
                [
                  _vm.isContent(content)
                    ? _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", { staticClass: "d-flex align-center" }, [
                            _c("img", {
                              attrs: {
                                src: content.is_video
                                  ? require("@/assets/ielts/course-details/video.svg")
                                  : require("@/assets/ielts/course-details/file-text.svg"),
                                alt: "content-icon",
                              },
                            }),
                            _vm._v(" " + _vm._s(content.title) + " "),
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "d-flex justify-end" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary--text",
                                  attrs: { elevation: "0", color: "#fff" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getComponentUrl(
                                        content.object_id,
                                        content.object_type
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Preview ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", { staticClass: "d-flex align-center" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/ielts/course-details/test.svg"),
                                alt: "content-icon",
                              },
                            }),
                            _vm._v(" " + _vm._s(content.title) + " "),
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "d-flex justify-end" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary--text",
                                  attrs: { elevation: "0", color: "#fff" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getExamAccess(content.id)
                                    },
                                  },
                                },
                                [_vm._v(" Preview ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          model: {
            value: _vm.preview,
            callback: function ($$v) {
              _vm.preview = $$v
            },
            expression: "preview",
          },
        },
        [
          _vm.preview
            ? _c("file-previewer", {
                attrs: {
                  "preview-url": _vm.previewContent.preview_url,
                  "download-url": _vm.previewContent.download_url,
                  title: `Certificate for ${_vm.previewContent.title}`,
                  "is-video": _vm.previewContent.is_video,
                  "is-download": _vm.previewContent.is_downloadable,
                },
                on: {
                  cancel: function ($event) {
                    _vm.preview = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }