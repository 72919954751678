var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-card",
                {
                  staticStyle: { color: "#000" },
                  attrs: { flat: "", width: "1385px" },
                },
                [
                  _c(
                    "h3",
                    {
                      staticClass:
                        "text-md-h4 text-sm-h5 font-weight-bold pl-2 pb-6",
                      staticStyle: { color: "#2b4570" },
                    },
                    [_vm._v(" Our course highlights ")]
                  ),
                  _vm.screenWidth < 600
                    ? _c("v-select", {
                        staticClass: "mt-8",
                        attrs: {
                          items: _vm.sections.map((section, i) => {
                            return {
                              text: `${section.title} (${section.contents.length})`,
                              value: i,
                            }
                          }),
                          dense: "",
                          outlined: "",
                        },
                        model: {
                          value: _vm.selectedSection,
                          callback: function ($$v) {
                            _vm.selectedSection = $$v
                          },
                          expression: "selectedSection",
                        },
                      })
                    : _c(
                        "v-slide-group",
                        {
                          staticClass: "pl-2 mt-8",
                          attrs: { mandatory: "", "show-arrows": "" },
                          model: {
                            value: _vm.selectedSection,
                            callback: function ($$v) {
                              _vm.selectedSection = $$v
                            },
                            expression: "selectedSection",
                          },
                        },
                        _vm._l(_vm.sections, function (section, index) {
                          return _c("v-slide-item", {
                            key: index,
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ active, toggle }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "mr-3 pa-2 d-flex align-center xl:text-sm lg:text-sm md:text-sm sm:text-xs",
                                          class:
                                            _vm.classes.slideItem.outer[active],
                                          attrs: {
                                            depressed: "",
                                            "input-value": active,
                                          },
                                          on: { click: toggle },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(section.title) + " "
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ml-2 rounded",
                                              class:
                                                _vm.classes.slideItem.inner[
                                                  active
                                                ],
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    section.contents.length
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        1
                      ),
                  _c(
                    "v-expansion-panels",
                    {
                      staticClass: "mt-sm-8 mt-md13",
                      attrs: { flat: "" },
                      model: {
                        value: _vm.expandedModule,
                        callback: function ($$v) {
                          _vm.expandedModule = $$v
                        },
                        expression: "expandedModule",
                      },
                    },
                    _vm._l(_vm.selected, function (module_id, index) {
                      return _c(
                        "v-expansion-panel",
                        {
                          key: index,
                          staticClass: "my-1",
                          attrs: { "active-class": "panel-active" },
                        },
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              staticClass: "pa-0 ma-0 my-1",
                              attrs: {
                                "hide-actions": "",
                                "disable-icon-rotate": "",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "py-5 px-4",
                                  attrs: { flat: "", color: "#F4F4F4" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-center",
                                      class: _vm.classes.header,
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-grow-1 clickable",
                                        },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(index + 1) +
                                                ".   " +
                                                _vm._s(
                                                  _vm.moduleName(module_id)
                                                )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        { attrs: { icon: "" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "panel-arrow",
                                              attrs: { size: "25" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  index != _vm.expandedModule
                                                    ? "mdi-plus"
                                                    : "mdi-close"
                                                ) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("public-module-contents", {
                                staticStyle: { color: "#f4f4f4" },
                                attrs: {
                                  contents: _vm.getModuleContents(module_id),
                                  module_id: module_id,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }