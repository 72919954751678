<template>
  <div>
    <v-card flat>
      <v-list>
        <v-list-item v-for="(content, index) in contents" :key="index">
          <v-row v-if="isContent(content)" no-gutters>
            <v-col class="d-flex align-center"
              ><img
                :src="
                  content.is_video
                    ? require('@/assets/ielts/course-details/video.svg')
                    : require('@/assets/ielts/course-details/file-text.svg')
                "
                alt="content-icon"
              />&nbsp;{{ content.title }}
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn
                elevation="0"
                class="primary--text"
                color="#fff"
                @click="getComponentUrl(content.object_id, content.object_type)"
              >
                Preview
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-else no-gutters>
            <v-col class="d-flex align-center">
              <img
                :src="require('@/assets/ielts/course-details/test.svg')"
                alt="content-icon"
              />&nbsp;{{ content.title }}
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn
                elevation="0"
                class="primary--text"
                color="#fff"
                @click="getExamAccess(content.id)"
              >
                Preview
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-card>
    <v-dialog v-model="preview">
      <file-previewer
        v-if="preview"
        :preview-url="previewContent.preview_url"
        :download-url="previewContent.download_url"
        :title="`Certificate for ${previewContent.title}`"
        :is-video="previewContent.is_video"
        :is-download="previewContent.is_downloadable"
        @cancel="preview = false"
      ></file-previewer>
    </v-dialog>
  </div>
</template>

<script>
import videoIcon from "@/assets/ielts/course-details/video.svg";
import fileIcon from "@/assets/ielts/course-details/file-text.svg";
import FilePreviewer from "~ecf/components/FilePreviewer";
import { checkStatus, loadDataWithContent } from "/global/utils/helpers";
import axios from "axios";

export default {
  name: "PublicModuleContents",
  components: { FilePreviewer },
  props: ["module_id", "contents"],
  data() {
    return {
      videoIcon: videoIcon,
      fileIcon: fileIcon,
      preview: false,
      renderNow: false,
      previewContent: undefined
    };
  },
  methods: {
    isContent(item) {
      return Boolean(item.object_type);
    },
    async getComponentUrl(object_id, object_type) {
      let channel = "public_get_url";
      let requestId = channel + Math.random(0, 99999999);
      const API_URL = process.env.VUE_APP_BACKEND_PUBLIC_COURSES_URL;
      let courseId = this.$ielts_course_id;
      let partnerCode = this.$ielts_partner_code;
      let auth = this.$store.state.user.authenticated
        ? { Authorization: `Bearer ${this.$store.state.user.authToken}` }
        : undefined;
      try {
        let response = await axios(API_URL, {
          method: "POST",
          data: {
            course_id: courseId,
            partner_code: partnerCode,
            object_id: object_id,
            object_type: object_type,
            module_id: this.module_id
          },
          headers: {
            "Content-Type": "application/json",
            "Channel-Name": channel,
            "Request-Id": requestId,
            ...auth
          }
        })
          .then(checkStatus)
          .then(loadDataWithContent);

        this.previewContent = response.response[0];
        this.preview = true;
        this.renderNow = true;
      } catch (error) {
        if (error.cause !== undefined) {
          this.$root.$emit("alert-access", {
            message: error.message,
            cause: error.cause,
            url: window.location.href
          });
        }
      }
    },
    async getExamAccess(exam_id) {
      let channel = "public_access_exam";
      let requestId = channel + Math.random(0, 99999999);
      const API_URL = process.env.VUE_APP_BACKEND_PUBLIC_COURSES_URL;
      let courseId = this.$ielts_course_id;
      let partnerCode = this.$ielts_partner_code;
      let auth = this.$store.state.user.authenticated
        ? { Authorization: `Bearer ${this.$store.state.user.authToken}` }
        : undefined;
      try {
        await axios(API_URL, {
          method: "POST",
          data: {
            exam_id,
            course_id: courseId,
            partner_code: partnerCode,
            module_id: this.module_id
          },
          headers: {
            "Content-Type": "application/json",
            "Channel-Name": channel,
            "Request-Id": requestId,
            ...auth
          }
        }).then(checkStatus);
        await this.$router.push({
          name: "entry-exam",
          params: {
            examId: exam_id
          }
        });
      } catch (error) {
        if (error.cause !== undefined) {
          this.$root.$emit("alert-access", {
            message: error.message,
            cause: error.cause,
            url: window.location.href
          });
        } else {
          this.$root.$emit("alert", [undefined, error.message]);
        }
      }
    }
  }
};
</script>

<style></style>
